import { PublicPlan } from '@wix/ambassador-pricing-plans-v2-plan/types';
import { planPurchaseClick, plansPageView } from '@wix/bi-logger-membership/v2';
import { ControllerFlowAPI, ControllerParams } from '@wix/yoshi-flow-editor';
import { EXPERIMENTS } from '../../../constants';
import { OrdersApi, PlansApi, PremiumApi } from '../../../services';
import { Analytics } from '../../../services/analytics';
import {
  ListProps,
  CommonProps,
  IntegrationData,
  PopupEnum,
  MessageCode,
  Modal,
  ModalType,
  noModal,
} from '../../../types/common';
import { PackagePickerInteractions } from '../../../types/PackagePickerFedops';
import { WIDGET_TYPE } from '../../../utils/bi';
import { errorToMessage, PurchaseLimitExceededError, toError } from '../../../utils/errors';
import { isFree } from '../../../utils/plan';
import { getOrderedPlans, getOrderedVisiblePlans } from '../../../utils/plan-visibility';
import { SettingsReader } from '../DefaultSettingsAdapter';
import { Router } from './Router';

export class ListController {
  constructor(
    protected setProps: (props: Partial<CommonProps & ListProps>) => void,
    protected wixCodeApi: ControllerParams['controllerConfig']['wixCodeApi'],
    protected flowAPI: ControllerFlowAPI,
    protected router: Router,
    protected plansApi: PlansApi,
    protected ordersApi: OrdersApi,
    protected premiumApi: PremiumApi,
    protected analytics: Analytics,
    protected settings: SettingsReader,
    protected demoPlans: PublicPlan[],
  ) {}

  async initialize(integrationData: IntegrationData) {
    return this.update(integrationData);
  }

  async update(integrationData: IntegrationData) {
    this.flowAPI.fedops.interactionStarted(PackagePickerInteractions.ListPageLoaded);

    const { plans } = await this.fetchAndOrderPlans(integrationData);

    if (this.flowAPI.environment.isViewer) {
      this.flowAPI.bi?.report(
        plansPageView({
          referralId: integrationData.biOptions?.referralId,
          referral_info: integrationData.biOptions?.referralInfo,
          widgetType: WIDGET_TYPE.page,
        }),
      );
    }
    this.analytics.addProductImpression(plans);

    this.setProps({
      plans,
      popup: null,
      hidePopup: this.hidePopup,
      selectPlan: (plan) => this.selectPlan(plan, integrationData),
      navigateToHomePage: () => this.router.gotoHomePage(),
    });
  }

  fetchAndOrderPlans = async (integrationData?: IntegrationData) => {
    let plans: PublicPlan[] = [];
    try {
      plans = await this.plansApi.loadPaidPlans(
        integrationData?.planIds
          ? {
              planIds: integrationData?.planIds,
            }
          : undefined,
      );
    } catch (e) {
      this.flowAPI.errorMonitor.captureException(toError(e));
    }
    const useFixture = !plans.length && this.wixCodeApi.window.viewMode === 'Editor';
    if (useFixture) {
      return { plans: this.demoPlans };
    }

    const useNewLimit = this.flowAPI.experiments.enabled(EXPERIMENTS.PLAN_LIMIT);
    return {
      plans: integrationData?.planIds
        ? getOrderedPlans(plans, this.settings)
        : getOrderedVisiblePlans(plans, this.settings, useNewLimit),
    };
  };

  protected selectPlan = async (plan: PublicPlan, integrationData: IntegrationData) => {
    this.flowAPI.fedops.interactionStarted(PackagePickerInteractions.PlanSelected);
    const planId = plan.id;

    if (!planId) {
      this.flowAPI.reportError('Plan has no id.');
      return;
    }

    if (this.wixCodeApi.window.viewMode !== 'Site') {
      if (this.isAlertModalsEnabled()) {
        this.setProps({ modal: { type: ModalType.PurchaseInPreview, onClose: this.closeModal } });
      } else {
        this.setProps({ popup: PopupEnum.checkoutPreview });
      }

      this.flowAPI.fedops.interactionEnded(PackagePickerInteractions.PlanSelected);
      return;
    }

    this.flowAPI.bi?.report(planPurchaseClick({ planGuid: plan.id!, widgetType: 'page' }));
    this.analytics.clickProduct(plan);

    this.selectPlanForUser(plan, integrationData);

    this.flowAPI.fedops.interactionEnded(PackagePickerInteractions.PlanSelected);
  };

  private selectPlanForUser = async (plan: PublicPlan, integrationData: IntegrationData) => {
    const { currentUser } = this.wixCodeApi.user;
    try {
      if (!isFree(plan) && (await this.premiumApi.shouldUpgrade())) {
        this.selectPlanForNonPremiumUser(plan, integrationData);
      } else if (!currentUser.loggedIn) {
        this.router.gotoCheckout(plan, integrationData);
      } else if (!isFree(plan) || this.flowAPI.experiments.enabled(EXPERIMENTS.FREE_PLAN_CHECKOUT)) {
        // Creating order preview ensures that purchase limit is not exceeded
        await this.ordersApi.createOrderPreview(plan.id!);
        this.router.gotoCheckout(plan, integrationData);
      } else {
        const order = await this.ordersApi.createOrderIfNotOverLimit(plan.id!);
        if (order) {
          this.router.gotoStatus(plan, order, integrationData);
        }
      }
    } catch (e) {
      if (e instanceof PurchaseLimitExceededError) {
        if (this.isAlertModalsEnabled()) {
          this.setProps({
            modal: {
              type: ModalType.PlanAlreadyPurchased,
              planName: plan.name!,
              onClose: this.closeModal,
            },
          });
        } else {
          this.setProps({ message: MessageCode.PURCHASE_LIMIT_ERROR });
        }
      } else {
        this.flowAPI.reportError(toError(e));
        this.setProps({ message: errorToMessage(toError(e)) });
      }
    }
  };

  private selectPlanForNonPremiumUser = async (plan: PublicPlan, integrationData: IntegrationData) => {
    const { currentUser } = this.wixCodeApi.user;
    this.setProps({ shouldUpgrade: true });
    if (currentUser.role === 'Admin') {
      const upgradeModal: Modal = {
        type: ModalType.Upgrade,
        onClose: this.closeModal,
      };
      const props = this.isAlertModalsEnabled()
        ? {
            modal: upgradeModal,
            continueToDemoCheckout: () => {
              this.closeModal();
              this.router.gotoCheckout(plan, integrationData);
            },
          }
        : {
            showUpgradeModal: true,
            continueToDemoCheckout: () => {
              this.setProps({ showUpgradeModal: false });
              this.router.gotoCheckout(plan, integrationData);
            },
          };

      this.setProps(props);
    } else if (this.isAlertModalsEnabled()) {
      this.setProps({ modal: { type: ModalType.CannotAcceptPayment, onClose: this.closeModal } });
    } else {
      this.router.gotoCheckout(plan, integrationData);
    }
  };

  private isAlertModalsEnabled() {
    return this.flowAPI.experiments.enabled(EXPERIMENTS.ALERT_MODALS);
  }

  protected hidePopup = () => this.setProps({ popup: null });
  private closeModal = () => this.setProps({ modal: noModal });
}
